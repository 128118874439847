<!--
 * @Author: zhangjiaheng
 * @Date: 2020-06-30 15:41:47
 * @LastEditTime: 2020-07-10 09:13:08
 * @LastEditors: Please set LastEditors
 * @Description: 回到顶部的html css 把需要监听的dom滚动id唯一传进来 即可
 * @FilePath: \user\src\views\home\myEn\components\readComm\viewBacktop.vue
-->
<template>
  <div v-show="show" class="box" @click="backTop">
    <div class="border"></div>
  </div>
</template>

<script>
export default {
  name: "BackTop",
  props: {
    // 监听元素的id
    elementId: {
      type: String,
      default: "enlis"
    }
  },
  data() {
    return {
      show: false,
      dom: {}
    };
  },
  mounted() {
    this.dom = window.document.getElementById(this.elementId);
    // 给特定dom添加滚动监听
    this.dom.addEventListener("scroll", this.showIcon);
  },
  methods: {
    // 判断是否显示 按钮
    showIcon() {
      if (!!this.dom.scrollTop && this.dom.scrollTop > 200) {
        // 页面高度大于200执行操作;
        this.show = true;
      } else {
        // 页面高度小于200执行操作;
        this.show = false;
      }
    },
    backTop() {
      this.dom.scroll(0, 0);
    }
  }
};
</script>

<style lang="scss" scoped>
.box{
  margin: 0 auto;
  width:48px;
  height:48px;
  background:#d4dae2;
  border-radius:50%;
  position:absolute;
  bottom: 8px;
  right: 35px;
  .border{
    width: 17px;
    height: 17px;
    border-left: 2px #ffffff solid;
    border-top: 2px #ffffff solid;
    position: absolute;
    left:50%;
    top:57%;
    transform: translate(-50%,-50%) rotate(45deg);
  }
}
</style>
