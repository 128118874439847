var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "box",
      on: { click: _vm.backTop },
    },
    [_c("div", { staticClass: "border" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }