/*
 * @Author: zhangjiaheng
 * @Date: 2020-07-03 11:24:50
 * @LastEditTime: 2021-09-03 17:11:41
 * @LastEditors: Please set LastEditors
 * @Description: 河洛 部分用的公共方法
 * @FilePath: \user\src\mixins\en\en.js
 */
import { date } from "@/tools/date";
import { enService } from "@/api/en/index";
import { otherEntranceRoute } from "@/components/businessDetail/helper.js";
import PayloginBox from "@/components/paylogin-box";
import { BusinessDataOpenType, BusinessDataPageType } from "@/components/businessDetail/data/BusinessCommonHeader";

export default {
  data() {
    return {
      // 函数节流器 1 未使用 2 使用中
      throttling: 1,
      getDateFormat: date.getDateFormat,
      type: "itself",
      // loading效果控制
      loading: true,
      flag: this.$route.query.flag,
      input: "",
      inputDate: "",
      // 偏好设置 是否显示批量按钮
      mainBatchOpera: false,
      // sortType=000升序、001降序
      sortType: "001",
      sortLoad: false
    };
  },
  mounted() {
    if (this.$route.fullPath === "/en") {
      this.type = "en";
    } else if (this.$route.fullPath === "/home/index") {
      this.type = "index";
    }
    // 查询偏好设置
    this.queryPreferences();
  },
  methods: {
    async queryPreferences() {
      try {
        const rsp = (await enService.queryPreferences({})) || {};
        if (typeof rsp.mainBatchOpera === "undefined" || rsp.mainBatchOpera === "000") {
          this.mainBatchOpera = true;
        } else {
          this.mainBatchOpera = false;
        }
      } catch (error) {
        console.log(error);
        this.mainBatchOpera = false;
      }
    },
    // 搜索的方法抽离
    async search(data, type) {
      if (this.throttling === 1) {
        this.throttling = 2;
        if ((data.input === "" || data.input === null) && (data.searchDate === "" || data.searchDate === null)) {
          this.inputDate = "";
          this.input = "";
          this.pageNo = 1;
          this.handle();
          this.params = {};
          try {
            await this.getList();
            this.throttling = 1;
          } catch (error) {
            this.throttling = 1;
          }
        } else {
          const params = {};
          this.pageNo = 1;
          this.handle();
          if (data.input) {
            if (type === "message") {
              params.message = data.input;
            } else {
              params.searchFlag = "001";
              params.searchText = data.input;
            }
            this.input = data.input;
          } else {
            this.input = "";
          }
          if (data.searchDate && data.searchDate !== null) {
            params.createTime = `${data.searchDate[0]}/${data.searchDate[1]}`;
            this.inputDate = params.createTime;
          } else {
            this.inputDate = "";
          }
          this.params = params;
          try {
            await this.getList();
            this.throttling = 1;
          } catch (error) {
            this.throttling = 1;
          }
        }
      }
      // 有字段了 点击搜索 跳转搜索内容 没有字段的时候 点击就收起
      this.hideSearch();
    },
    // 加载 更多 每次 pageNo加1
    addMore(url) {
      if (this.type === "en") {
        this.$router.push({
          path: url,
          query: {
            flag: url
          }
        });
      } else {
        this.pageNo += 1;
        this.getList(true);
      }
    },
    // 处理 再哪里引用的组件
    handle() {
      if (this.type === "itself") {
        this.pageSize = 10;
        if (this.pageType === "awitDo") {
          this.pageSize = 20;
        }
      } else if (this.type === "en") {
        this.pageSize = 5;
      } else if (this.type === "index") {
        this.pageSize = 3;
        if (this.pageType === "awitDo") {
          this.pageSize = 10;
        }
      }
    },
    // 首页跳转到详情
    goDetails(url) {
      this.$router.push(url);
    },
    // 回到上一页
    callback() {
      this.$router.go(-1);
    },
    // 隐藏搜索框 暂时不隐藏
    hideSearch() {
      if (!this.input && !this.inputDate) {
        this.show = !this.show;
      }
    },
    // 处理消息中心的 操作
    async messageOperation(item) {
      const tripList = ["001", "002", "003", "011", "012", "013", "014", "015", "016"];
      if (item.type === "008" || item.type === "009") {
        // 008业务建模009基础数据
        this.$router.push(
          otherEntranceRoute({
            id: item.businessKey,
            businessType: item.type === "009" ? "1" : "2",
            templateId: item.formId || "",
            templateName: item.templateName || ""
          })
        );
        // url = `/toModelUserComm.do?tenantId=${item.tenantId}&id=${item.businessKey}&templateId=${item.formId || ""}&type=${item.type == "009" ? "1" : "2"}`;
      } else if (["018", "023", "024"].indexOf(item.type) > -1) {
        // 018待办提醒，23报销提醒 24业务建模不跳转不弹窗 无操作
      } else if (item.type === "010") {
        // 跳到行程单详情
        this.$router.push({ path: "/business-travel/trip/tripDetail", query: { tripId: item.businessKey } });

        // window.location.href = "/toTripAdd.do?id=" + item.businessKey;
      } else if (tripList.indexOf(item.type) > -1) {
        // 行程单处理
        let tripNodeType = "000";
        if (item.type === "002") {
          tripNodeType = "001";
        } else if (item.type === "003") {
          tripNodeType = "003";
        } else if (item.type === "011") {
          tripNodeType = "000";
        } else if (item.type === "012") {
          tripNodeType = "001";
        } else if (item.type === "013" || item.type === "014" || item.type === "015" || item.type === "016") {
          tripNodeType = "003";
        }
        const obj = {
          id: item.businessKey,
          tripNodeType
        };
        this.currentNode = obj;
        this.noticeType.nodeDetailShow.flg = true;
        // url = "/toTripNodeDetail.do?type=" + tripNodeType + "&id=" + item.businessKey + "&tripNodeType=" + tripNodeType + "&loginUserId=" + ($("#userId").val() + "");
      } else if (item.type === "017") {
        // 机票重新预订
        // this.$message({
        //   showClose: true,
        //   message: "机票重新预订",
        //   type: "warning"
        // });
      } else if (item.type === "019") {
        // 公告详情
        const rspData = await enService.querySysNoticeInfo({ id: item.businessKey });
        this.noticeType.type019.content = rspData.content;
        this.noticeType.type019.flg = true;
      } else if (["021", "022", "023"].indexOf(item.type) > -1) {
        // 无操作
      } else if (item.type === "026") {
        // 跳转 基础数据详情

        this.$router.push({
          path: "businessModel/detail",
          query: {
            id: item.businessKey,
            businessType: "100",
            objectTypeId: item.formId || "",
            openType: BusinessDataOpenType.view,
            pageType: BusinessDataPageType.normal
          }
        });

        // openType=3&pageType=3&objectTypeId=171211134003906183&businessType=100&id=349795657724268544
        // url = `/toBaseDataDetail.do?objectTypeId=${item.formId}&flg=quotes&id=${item.businessKey}`;
      } else if (item.type === "025") {
        // this.$message({
        //   showClose: true,
        //   message: "开票通知",
        //   type: "warning"
        // });
        // 025 开票通知（025）和申诉通知（027）
        // url = `/toTicketApplyDetail.do?tenantId=${item.tenantId}&id=${item.businessKey}`;
      } else if (item.type === "027") {
        // this.$message({
        //   showClose: true,
        //   message: "申诉通知",
        //   type: "warning"
        // });
        // url = `/toTicketAppealDetail.do?tenantId=${item.tenantId}&dataId=${item.businessKey}`;
      } else if (item.type === "028" || item.type === "029") {
        // 任务通知 提醒
        this.$router.push(
          otherEntranceRoute({
            id: item.businessKey,
            businessType: 3,
            templateId: item.templateId,
            templateName: item.templateName || ""
          })
        );
        // url = `/toModelUserComm.do?templateId=${item.templateId || ""}&id=${item.businessKey}&type=3&flg=`;
      } else if (item.type === "030") {
        // 个人设置授权提醒
        // setting/basic
        this.$router.push({ path: "setting/authorize" });
      } else if (item.type === "031") {
        // 下载中心 下载通知
        // download-management/home
        this.$router.push({ path: "download-management/home" });
        // window.location.href = "/toDownCenter.do";
      } else if (item.type === "033") {
        // 考勤提醒
        // 通知对象为考勤人员时，跳转考勤记录列表
        // hr/attendance/chart
        this.$router.push({ path: "hr/attendance/chart" });
      } else if (item.type === "034") {
        // 通知对象为各部门负责人/直属领导时，自动跳转系统内置的考勤报表，若该负责人无查看该报表权限，则提示：“没有查看权限，请联系管理员”
        // hr/attendance/list
        this.$router.push({ path: "hr/reportAttend", query: { templateId: item.businessData } });
        // window.location.href = "/toReportAttend.do?templateId="+(typeof(item.businessData)=="object"?"":item.businessData);
      } else if (item.type === "035") {
        let flgqx = 1;
        this.menuList.forEach((v1) => {
          if (v1.code === "049") {
            flgqx = 2;
          }
          (v1.dataList || []).forEach((v2) => {
            if (v2.code === "049") {
              flgqx = 2;
            }
          });
        });
        if (flgqx === 1) {
          this.$message({
            showClose: true,
            message: "没有\"我的工资条\"页面权限，请联系管理员",
            type: "warning"
          });
        }
        const res = (await enService.queryPaySlipsDetail({ id: item.businessKey })) || "";
        if (res === "") {
          this.$message({
            showClose: true,
            message: "没有工资条信息",
            type: "warning"
          });
          return;
        }
        if (res.lockStatus === "1") {
          this.$message({
            showClose: true,
            message: "该工资条已被锁定，请联系管理员或HR",
            type: "warning"
          });
          return;
        }
        this.noticeType.type035.content = item.businessKey;

        PayloginBox({
          userInfo: this.$store.getters.userInfo,
          payRollId: this.noticeType.type035.content
        })
          .then(async(res) => {
            if (res.data === "success") {
              this.$router.push({
                path: "/payroll/paystub",
                query: {
                  id: item.businessKey
                }
              });
            } else if (res.data === "needLogin") {
              // 验证失败 锁定 加退出
              const p = { id: item.businessKey };
              p.updateData = JSON.stringify({ lockStatus: "1" });
              await enService.updatePaySlip(p);
              this.$router.push({ path: "/login" });
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
        // 工资条 信息跳转
        // 1验证权限
        // 2获取菜单信息
        // 3判断是否有 我的工资条 权限
        // 没有权限 提示 "没有\"我的工资条\"页面权限，请联系管理员"
        // 有权限 queryPaySlipsDetail.ajax 查询详细信息 item.businessKey
        // if (res == "") {
        //   layer.msg("没有工资条信息");
        //   return;
        // }
        // if (res.lockStatus == "1") {
        //   layer.msg("该工资条已被锁定，请联系管理员或HR");
        // } else {
        //   utils.popPasswordconfirmation(
        //     `/myPaySlip.do?id=${item.businessKey}`,
        //     "1",
        //     item.businessKey
        //   );
        // }
      } else if (["036", "037", "038", "039", "040", "051", "052", "053", "054", "055", "056", "057", "058", "059", "060", "061"].indexOf(item.type) > -1) {
        // 魔钥 无操作
      } else if (item.type === "041") {
        // 文档 更新 041
        this.$router.push({
          path: "/document-management/home",
          query: {
            id: item.businessKey
          }
        });
      } else if (item.type === "042") {
        // 接口平台
        // this.$message({
        //   showClose: true,
        //   message: "接口平台",
        //   type: "warning"
        // });
        // window.open(`/apiPlat/api/details/${item.businessKey}`)
      } else if (item.type === "043") {
        // 方案中心 通知 043： 跳转  047  不跳转
        this.$router.push({ path: "plan/myPlan" });
        // window.location.href = "/toPlanUserList.do";
      } else if (item.type === "047") {
        // 方案无操作
      } else if (item.type === "046") {
        // 酒店异常信息 无操作
      } else if (item.type === "048") {
        // 税务 体检
        const rspData = await enService.getTaxCheckResultDetail({ checkSchemeResultId: item.businessKey });
        if (JSON.stringify(rspData) === "{}") {
          this.$message({
            message: "无体检结果",
            type: "warning"
          });
        } else {
          this.noticeType.type048.flg = true;
          this.noticeType.type048.content = rspData;
        }
      } else if (item.type === "049" || item.type === "050") {
        // 评论的通知
        const bda = item.businessData;
        this.$router.push(
          otherEntranceRoute({
            id: bda.id,
            businessType: bda.type,
            templateId: bda.templateId,
            templateName: bda.templateName || ""
          })
        );
        // const bda = item.businessData;
        // url = `/toModelUserComm.do?id=${bda.id}&templateId=${bda.templateId || ""}&type=${bda.templateType}`;
      } else if (item.type === "099") {
        // 回执 跳转
        this.$router.push({ path: "/myReceipt" });
      } else if (item.type === "101") {
        // 智能核算的
      }
    },
    // 查询排序 待办已办的排序
    async queryUserConfig(type) {
      const p = { configKey: type };
      const res = (await enService.queryUserConfig(p)) || "";
      if (res === undefined || res === "" || res.value === "") {
        // 第一次不存在 默认 001；
      } else {
        this.sortType = res.value;
      }
      this.sortLoad = true;
      // console.log(res);
      // 查询列表
      this.getList();
    },
    // 排序按钮点击
    sortClick(flg, type) {
      this.sortType = flg;
      this.pageNo = 1;
      this.getList();
      const p = {
        configKey: type,
        configValue: this.sortType
      };
      enService.saveUserConfig(p);
    },
    payRollVerfyCallBack() {
      console.log(111);
    }
  }
};
